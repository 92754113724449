:root {
    --primary-color: #6C63FF;
    --secondary-color: #4A4754;
    --accent-color: #FF6B6B;
    --text-color: #333333;
    --bg-color: #F4F7FE;
    --gradient: linear-gradient(135deg, #6C63FF, #FF6B6B);
    --card-bg: rgba(255, 255, 255, 0.9);
    --footer-bg: #2C2B3C;
    --footer-text: #ffffff;
  }
  
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
    background-color: var(--bg-color);
    color: var(--text-color);
    line-height: 1.6;
    overflow-x: hidden;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  header {
    background-color: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    transition: all 0.3s ease;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }
  
  .logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 24px;
  }
  
  .logo img {
    height: 80px;
    margin-right: 10px;
  }
  
  .nav-links a {
    margin-left: 30px;
    text-decoration: none;
    color: var(--text-color);
    font-weight: 500;
    transition: color 0.3s ease;
    position: relative;
  }
  
  .nav-links a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: var(--accent-color);
    transition: width 0.3s ease;
  }
  
  .nav-links a:hover::after {
    width: 100%;
  }
  
  .hero {
    background: var(--gradient);
    padding: 180px 0 120px;
    text-align: center;
    color: white;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    margin-bottom: 80px;
  }
  
  .hero h1 {
    font-size: 48px;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  }
  
  .hero p {
    font-size: 20px;
    max-width: 800px;
    margin: 0 auto 40px;
    opacity: 0.9;
  }
  
  .cta-button {
    display: inline-block;
    background-color: white;
    color: var(--primary-color);
    padding: 15px 30px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.2);
    background-color: var(--accent-color);
    color: white;
  }
  
  .features {
    padding: 100px 0;
  }
  
  .features h2 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 50px;
    color: var(--primary-color);
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .feature-card {
    background-color: var(--card-bg);
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  }
  
  .feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(108, 99, 255, 0.2);
  }
  
  .feature-icon {
    font-size: 48px;
    color: var(--accent-color);
    margin-bottom: 20px;
  }
  
  .feature-card h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: var(--primary-color);
  }
  
  .demo-section {
    background-color: var(--secondary-color);
    color: white;
    padding: 100px 0;
    text-align: center;
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  }
  
  .demo-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  
  .demo-video {
    max-width: 800px;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 15px 50px rgba(0,0,0,0.3);
  }
  
  .testimonials {
    padding: 100px 0;
    background-color: #f9f9f9;
  }
  
  .testimonial-slider {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .testimonial {
    text-align: center;
    padding: 30px;
  }
  
  .testimonial img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .testimonial p {
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .testimonial-author {
    font-weight: bold;
    color: var(--primary-color);
  }
  
  footer {
    background-color: var(--footer-bg);
    color: var(--footer-text);
    padding: 80px 0 60px;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
    margin-bottom: 20px;
  }
  
  .footer-section h3 {
    color: var(--accent-color);
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .footer-links a {
    color: var(--footer-text);
    text-decoration: none;
    display: block;
    margin-bottom: 8px;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: var(--accent-color);
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .social-links a {
    color: var(--footer-text);
    font-size: 24px;
    margin: 0 15px;
    transition: all 0.3s ease;
  }
  
  .social-links a:hover {
    color: var(--accent-color);
    transform: translateY(-3px);
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
  
  .floating-icon {
    animation: float 3s ease-in-out infinite;
  }
  